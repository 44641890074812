.rri6hjt.si9dx7r{position:fixed;left:0;top:var(--header-height);width:100%;z-index:100;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:0 18px;}@media (min-width:1280px){.rri6hjt.si9dx7r{padding:0 30px;}}.dark .rri6hjt.si9dx7r{background-color:#464646;}
.i7lc6f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;}
.mdz7696.sbc5f8g{font-size:8px;margin-left:5px;margin-right:5px;}
.n7c148f{font-size:12px;font-weight:normal;font-family:"Open Sans";-webkit-letter-spacing:0.35px;-moz-letter-spacing:0.35px;-ms-letter-spacing:0.35px;letter-spacing:0.35px;text-transform:uppercase;}
.n1i0evhn{font-size:12px;font-weight:normal;font-family:"Open Sans";-webkit-letter-spacing:0.35px;-moz-letter-spacing:0.35px;-ms-letter-spacing:0.35px;letter-spacing:0.35px;text-transform:uppercase;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.n1fjtct1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.n1fjtct1 .n1i0evhn:last-child{font-weight:500;}
.n1002d6x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;cursor:pointer;}.n1002d6x.disabled{opacity:0.5;}
.n1irqfh7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-left:8px;}
.vvdnu97.n7c148f{margin-left:10px;margin-right:10px;}
.n1a3cftq.si9dx7r{position:relative;}
