.slide-up-enter {
  transform: translate(0, 120%);
}
.slide-up-enter-active {
  transform: translate(0);
}
.slide-up-enter-done {
  transform: translate(0, 0);
}

.slide-up-exit-active {
  transform: translate(0, 120%);
}
.slide-up-exit {
  transform: translate(0, 120%);
}
.slide-up-exit-done {
  transform: translate(0, 120%);
}
